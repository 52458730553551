import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import photo1 from "./photo1.jpg";
import photo2 from "./photo2.jpg";
// import photo from "./photo.jpg";
// import photo3 from "./photo3.jpg";
// import photo4 from "./photo4.jpg";

import "./App.scss";
import heart from "./Heart.svg";
import Countdown, { CountdownRenderProps } from "react-countdown";
import Leaf4 from "./Leaf_04.png";
import leaf1 from "./Leaf_01.png";
import leaf2 from "./Leaf_02.png";
import "./i18n"; // Импортируйте конфигурацию i18next
import { Link } from "react-router-dom";

function App_kz() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage('kz');
  }, [i18n]);
  // ================================================================

  const [formData, setFormData] = useState({
    fullname: "",
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState<{ fullname?: string; count?: string }>(
    {}
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validate = () => {
    const newErrors: { fullname?: string; count?: string } = {};
    if (!formData.fullname) {
      newErrors.fullname = "Полное имя обязательно";
    }
    if (!formData.count) {
      newErrors.count = "Количество обязательно";
    }
    return newErrors;
  };

  // const handleSubmit = async () => {
  //   setLoading(true);
  //   setSubmitted(false);

  //   const response = await fetch("https://www.pir-dev.kz/send", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       fullname: formData.fullname,
  //       count: Number(formData.count),
  //     }),
  //   });

  //   if (response.ok) {
  //     console.log(t("sent"));
  //     setSubmitted(true);
  //   } else {
  //     console.error(t("error"));
  //   }

  //   setLoading(false);
  // };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    setSubmitted(false);
    setErrors({});

    const response = await fetch("https://www.pir-dev.kz/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullname: formData.fullname,
        count: Number(formData.count),
      }),
    });

    if (response.ok) {
      console.log(t("sent"));
      setSubmitted(true);
    } else {
      console.error(t("error"));
    }

    setLoading(false);
  };

  // ================================================================
  const weddingDate = new Date("2024-08-25T18:00:00");
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    const totalDays = days + hours / 24 + minutes / 1440 + seconds / 86400;

    let textColor = "black";
    if (totalDays < 3) {
      textColor = "#770707";
    } else if (totalDays < 7) {
      textColor = "#f5b401";
    }

    return (
      <div style={{ color: textColor }}>
        {completed ? (
          <span>{t("wedding_completed")}</span>
        ) : (
          <Countdown date={weddingDate} />
        )}
      </div>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
      <button className="translate">
          <Link to={'/'}>
          KZ
        </Link>
        </button>        <div className="date">
          <p>{t("august")}</p>
          <div className="year">
            <p>20</p>
            <p>24</p>
          </div>
        </div>
        <div className="week">
          <div className="day">
            <p className="Dname">{t("monday")}</p>
            <p>19</p>
          </div>
          <div className="day">
            <p className="Dname">{t("tuesday")}</p>
            <p>20</p>
          </div>
          <div className="day">
            <p className="Dname">{t("wednesday")}</p>
            <p>21</p>
          </div>
          <div className="day">
            <p className="Dname">{t("thursday")}</p>
            <p>22</p>
          </div>
          <div className="day">
            <p className="Dname">{t("friday")}</p>
            <p>23</p>
          </div>
          <div className="day">
            <p className="Dname">{t("saturday")}</p>
            <p>24</p>
          </div>
          <div className="day">
            <p className="Dname">{t("sunday")}</p>
            <p>25</p>
          </div>
          <img className="heart" src={heart} />
        </div>
        {/* <div className="image">
          <p className="title">{t("invite_you")}</p>
          <img src={photo2} className="App-logo" alt="logo" /> */}
          {/* <img src={photo} className="App-logo" alt="logo" /> */}
        {/* </div> */}
        <Swiper
          modules={[Autoplay]}
          className="swiper"
          spaceBetween={10}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper: any) => console.log(swiper)}
          autoplay={{
            delay: 3000, // задержка между слайдами в миллисекундах
            disableOnInteraction: false, // не отключать автопрокрутку после взаимодействия с слайдером
          }}
          speed={7000}
        >
          <SwiperSlide className="slide">
            <img className="photo" src={photo1} />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img className="photo" src={photo2} />
          </SwiperSlide>
        </Swiper>

        <div className="content">
          <p className="titleCon">{t("wedding_of_our_children")}</p>
          <p className="couple">{t("Damir")} <span>&</span> {t("Akmaral")}</p>
          <img className="leaf_left" src={leaf1} />
          <img className="leaf_right" src={leaf2} />
          <p className="paragraph">
            {t("wedding_invitation")} <br />{t("wedding_invitation2")}
          </p>
          <a className="adress" href="https://2gis.kz/almaty/firm/70000001029769453">
            {t("restaurant_address")}
            <br />
            {t("city_address")}
          </a>
          <p className="parents">
            Той иелері <br />
            <span>Фуркат - Малика</span>
          </p>
        </div>
        <p className="timer">
          <h6>{t("time_left")}</h6>
          <Countdown date={weddingDate} renderer={renderer} />
          <div className="dhms">
            <p>{t("days")}</p>
            <p>{t("hours")}</p>
            <p>{t("minutes")}</p>
            <p>{t("seconds")}</p>
          </div>
        </p>
        <img src={Leaf4} className="leaf" />
        <form className="guestion" onSubmit={handleSubmit}>
            <input
              name="fullname"
              type="text"
              className="name"
              placeholder={t("your_full_name")}
              value={formData.fullname}
              onChange={handleChange}
              required
            />
            <input
              name="count"
              type="number"
              className="count"
              placeholder={t("number_of_people")}
              onChange={handleChange}
              required
            />
          <button
            className="button-89"
            role="button"
            type="submit"
            disabled={loading}
          >
            {loading ? t("loading") : submitted ? t("sent") : t("send")}
          </button>
        </form>
      </header>
    </div>
  );
}

export default App_kz;
