import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "./logo.svg";
import "./App.scss";
import photo from "./photo.jpg";
import heart from "./Heart.svg";
import Countdown, { CountdownRenderProps } from "react-countdown";
import Leaf1 from "./Leaf_01.png";
import Leaf2 from "./Leaf_02.png";
import Leaf3 from "./Leaf_03.png";
import Leaf4 from "./Leaf_04.png";
import "./i18n"; // Импортируйте конфигурацию i18next
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import App_ru from "./App_ru";
import App_uz from "./App_uz";
import App_kz from "./App_kz";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App_ru />} />
          <Route path="*" element={<App_ru />} />
          <Route path="/uz" element={<App_uz />} />
          <Route path="/kz" element={<App_kz />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
{/* <Link to={'/'}>На главную</Link> */}